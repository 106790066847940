import './styles/security.scss';
import React from 'react';
import * as THREE from 'three';
import BIRDS from 'vanta/dist/vanta.birds.min';

function getParameter (sVar) {
  return unescape(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + escape(sVar).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

if (getParameter("i")){
    document.querySelector('header fieldset').classList.add("hidden");
    document.querySelector('header p').classList.add("hidden");
    document.querySelector('form[name="user"] fieldset > div:last-of-type').classList.add("hidden");
    document.querySelector('form[name="user"] fieldset > div:nth-of-type(1)').classList.add("hidden");
    document.querySelector('form[name="user"] fieldset > div:nth-of-type(2)').classList.add("hidden");
    document.querySelector('#user_invitation').value = getParameter("i");
    document.querySelector('#user_email').value = "none@kod84.com";
    document.querySelector('#user_username').value = "none";
}

VANTA.BIRDS({
    el: "body",
    mouseControls: true,
    touchControls: true,
    minHeight: 200.00,
    minWidth: 200.00,
    scale: 1.00,
    scaleMobile: 1.00,
    backgroundColor: 0x1e1e1e,
    color1: 0x111111,
    color2:0xccff7f,
    colorMode: "lerp",
    birdSize: 2.20,
    wingSpan: 20.00,
    separation: 41.00,
    alignment: 41.00,
    cohesion: 56.00,
    quantity: 1.00
})



